
<template>
  <div>
    <Integrations v-if="!isSuperAdmin" formType="Customer" :showForm="showCustomerForm" />
    <Integrations v-if="isSuperAdmin" formType="Fulfiller" :showForm="showFulfillerForm" />
  </div>
</template>

<script>
import Integrations from '@/integrations/Integrations'
import { mapGetters } from 'vuex'
export default {
  components: {
    Integrations
  },
  data () {
    return {
      showCustomerForm: true,
      showFulfillerForm: true
    }
  },
  computed: {
    isSuperAdmin () {
      return this.getMyIds?.SUPERADMIN?.includes('SUPERADMIN')
    },
    ...mapGetters({
      getMyIds: 'login/getMyIds'
    })
  }
}
</script>
