<template>
  <div style="margin-top: 40px;">
  <div style="display: flex; justify-content: flex-end;">
    <v-btn
      elevation="2"
      @click="toggleCreateForm()"
      color="primary"
      style="margin: 10px"
    >
      {{$t('buttons.create')}}
    </v-btn>
  </div>
    <transition name="fade">
      <div v-if="showForm && showCreateForm">
        <v-sheet
          width="95%"
          height="95%"
          :elevation="1"
          :tile="false"
          style="margin: auto; padding: 10px;"
        >
          <h2 class="text-center mb-4 mt-4 " >
              <span > {{$t('forms.new')}} {{formType}}</span>
          </h2>
          <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; flex-wrap: wrap; width: 100%;">
            <v-text-field
              v-model="formField"
              solo
              :label="$t(`users.` + formType)  + ' Name'"
              clearable
              style=" margin: auto 8px auto auto;max-width: 43%;"
            ></v-text-field>
            <div v-for="(domain, index) in domainNames" :key="index + 'domain'" style="margin: auto auto ;max-width: 43%; ">
              <v-text-field
                v-model="domainNames[index]"
                v-value="domain"
                solo
                :label="$t('users.domain_name')"
                :append-outer-icon="index === domainNames.length-1  ? 'mdi-plus-box-multiple' : ''"
                :append-icon="index > 0 ? 'mdi-trash-can-outline' : ''"
                @click:append-outer="addDomain()"
                @click:append="removeDomain()"
                style="margin-left:8px"
              ></v-text-field>
            </div>
          </div>
          <v-btn
            elevation="2"
            @click="toggleCreateForm()"
            color="secondary"
            style="margin: auto 10px"
          >
            {{$t('buttons.cancel')}}
          </v-btn>
          <v-btn
            elevation="2"
            @click="submitForm()"
            :loading="saving"
            color="primary"
            style="margin: auto 10px"
          >
            {{$t('buttons.create')}}
          </v-btn>
        </v-sheet>
      </div>
    </transition>
    <v-sheet
      width="95%"
      height="95%"
      :elevation="4"
      color="rgba(120,120,120,0.5)"
      :tile="false"
      style="margin: auto; margin-top: 20px;"
    >
      <template v-if="structuredTableData.length > 0">
      <v-simple-table
        fixed-header height="100%"
        style=" margin: auto;"
      >
        <thead>
        <tr>
          <th scope="col" style="width: 22%; font-size: 22px;">Name</th>
          <th scope="col" style="width: 25%; font-size: 22px;">Domain</th>
          <th scope="col" style="width: 22%; font-size: 22px;">ID</th>
          <th scope="col" style="width: 21%; font-size: 22px;">Type</th>
          <th scope="col" style="width: 10%; font-size: 22px;"></th>
        </tr>
        </thead>
        <tbody style="text-align: left;">

        <tr v-for="skel in tableLoading" :key="skel">
          <UserTableSkeleton v-for="item in [1,2,3,4]" :key="item + '-Skeleton'" />
        </tr>
        <tr v-for="(row, ind) in structuredTableData" :key="'table-row-'+ind">
          <td>{{ row.name }}</td>
          <td>{{ row.domain }}</td>
          <td>{{ row.id }}</td>
          <td>{{ row.type }}</td>
          <td>
            <v-btn
              color="primary"
              elevation="2"
              fab
              v-if="row.type === 'Customer'"
              icon
              small
              :loading="deleteLoading.includes(row.id + '-' + row.type + '-' + row.name)"
              @click="dialog = true, deletingRow = row"
            >
              <Icon
                iconName="trash-fill"
                size="15px"
                color="grey"
                :is-bootstrap="true"
              />
            </v-btn>
          </td>
        </tr>
        </tbody>
      </v-simple-table>
      </template>
    </v-sheet>
    <div class="text-center">
      <v-dialog
        v-model="dialog"
        width="500"
      >
        <v-card>
          <v-card-title class="headline grey lighten-2">
            Are you Sure?
          </v-card-title>
          <v-card-text style="padding-top: 20px; padding-bottom: 4px;">
            Deleting an Account removes all Customers, Containers, and Devices within that account. Are you sure you want to delete all of this?
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="dialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="trashRow(deletingRow), dialog = false"
            >
              I'm Sure
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import UserTableSkeleton from '@/components/users/UserTableSkeleton'
import Icon from '@/components/elements/Icon'

export default {
  name: 'Integrations',
  components: { UserTableSkeleton, Icon },
  props: {
    formType: String,
    showForm: Boolean
  },
  data () {
    return {
      formField: '',
      domainNames: [''],
      saving: false,
      loading: [],
      deleteLoading: [],
      editingRow: null,
      rows: [],
      customerData: [],
      fulfillerData: [],
      showCreateForm: false,
      tableLoading: [1, 2, 3, 4],
      dialog: false,
      deletingRow: {}
    }
  },
  async mounted  () {
    this.tableLoading = []
  },
  computed: {
    ...mapGetters({
      getAvailableCustomers: 'login/getAvailableCustomers',
      getCurrentCustomer: 'login/getCurrentCustomer',
      myFulfillers: 'login/myFulfillers',
      myCustomers: 'login/myCustomers'
    }),
    structuredTableData () {
      let tableData = []
      if (this.tableLoading.length === 0) {
        const arr = this.myFulfillers
        const sortedArr = arr.sort((x, y) => (x.indexedName > y.indexedName) ? 1 : ((y.indexedName > x.indexedName) ? -1 : 0))
        sortedArr.forEach(el => {
          tableData.push({
            type: 'Fulfiller',
            name: el?.name,
            domain:
              el?.domainNames?.join(','),
            id: el?.id
          })
        })
        this.myCustomers.forEach(el => {
          tableData.push({
            type: 'Customer',
            name: el?.name,
            domain:
              el?.domainNames?.join(','),
            id: el?.id
          })
        })
      }
      return tableData
    },
    sectionTitle () {
      return 'Customer'
    }
  },
  methods: {
    ...mapActions({
      createCustomer: 'login/createCustomer',
      createFulfiller: 'login/createFulfiller',
      deleteAccount: 'login/deleteAccount'
    }),
    toggleCreateForm () {
      this.showCreateForm = !this.showCreateForm
    },
    addDomain () {
      this.domainNames.push('')
    },
    removeDomain () {
      this.domainNames.pop()
    },
    editRow (row) {
      this.editingRow = this.editingRow === row ? null : row
    },
    async submitForm  () {
      this.saving = true
      const data = {
        name: this.formField,
        domainNames: this.domainNames
      }
      this.formType === 'Customer' ? await this.createCustomer({ customer: data }) : await this.createFulfiller({ fulfiller: data })
      this.clearForm()
    },
    async trashRow (item) {
      this.deleteLoading.push(item.id + '-' + item.type + '-' + item.name)
      if (item.type === 'Fulfiller') {
        await this.deleteAccount({
          id: item.id,
          type: 'fulfillers'
        })
        this.fulfillerData = this.fulfillerData.filter(el => el.id !== item.id)
      } else if (item.type === 'Customer') {
        await this.deleteAccount({
          id: item.id,
          type: 'customers'
        })
        this.customerData = this.customerData.filter(el => el.id !== item.id)
      }
      this.deleteLoading = this.deleteLoading.filter(el => el !== item.id + '-' + item.type + '-' + item.name)
      this.deletingRow = {}
    },
    clearForm () {
      this.formField = ''
      this.domainNames = ''
      this.saving = false
    }
  },
  watch: {
    myFulfillers (newVal, oldVal) {
      this.fulfillerData = newVal || oldVal
    },
    myCustomers (newVal, oldVal) {
      this.customerData = newVal || oldVal
    }
  }
}
</script>
